import request from '../../helper/request';

export function getDrivers(params) {
  return request('/drivers', {
    method: 'GET',
    params: params,
  });
}

export function getDriver(id) {
  return request('/drivers/' + id, {
    method: 'GET',
  });
}

export function createDriver(params) {
  return request('/drivers', {
    method: 'POST',
    data: params,
  });
}

export function updateDriver(params, id) {
  return request('/drivers/' + id, {
    method: 'PATCH',
    data: params,
  });
}

// export function removeDriver(id) {
//   return request('/drivers/' + id, {
//     method: 'DELETE',
//   });
// }
