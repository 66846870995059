import React from 'react';
import './OptionalLabel.scss';

const OptionalLabel = ({ title }) => {
  return (
    <div className="OptionalLabel">
      <p>{title}</p>
      <span>Optional</span>
    </div>
  );
};

export default OptionalLabel;
