import { combineReducers } from '@reduxjs/toolkit';

import commonReducer from './slice';
import authReducer from '../modules/auth/stores/slice';
import JobOrderReducer from '../modules/jobOrders/stores/slice';
import userReducer from '../modules/users/stores/slice';
import clientReducer from '../modules/client/stores/slice';
import driverReducer from '../modules/driver/stores/slice';

const appReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  jobOrder: JobOrderReducer,
  user: userReducer,
  client: clientReducer,
  driver: driverReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
