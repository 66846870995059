import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  doCancelJobOrder,
  doCreateJobOrder,
  doGetDirections,
  doGetJobOrder,
  doGetJobOrders,
  doGetTypes,
} from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('jobOrders/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('jobOrders/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('jobOrders/') && action.type.endsWith('/rejected');
};

const jobOrdersAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const jobOrderSlice = createSlice({
  name: 'jobOrder',
  initialState: {
    action: null,
    jobOrderDetail: null,
    isFetching: false,
    directions: null,
    status: 'idle',
    metadata: {},
    entities: [],
    types: [],
    vehicles: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetJobOrders.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetJobOrders.fulfilled, (state, action) => {
      const { jobOrders, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      jobOrdersAdapter.setAll(state, jobOrders);
    });
    builder.addCase(doGetJobOrders.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetJobOrder.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetJobOrder.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.jobOrderDetail = action.payload;
    });
    builder.addCase(doGetJobOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetDirections.pending, (state) => {
      state.action = 'get directions';
      state.status = 'loading';
    });
    builder.addCase(doGetDirections.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.directions = action.payload;
    });
    builder.addCase(doGetDirections.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateJobOrder.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateJobOrder.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateJobOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetTypes.pending, (state) => {
      state.action = 'getTypes';
      state.status = 'loading';
    });
    builder.addCase(doGetTypes.fulfilled, (state, action) => {
      state.types = action.payload.jobOrderTypes;
      state.vehicles = action.payload.vehicles;
      state.status = 'succeeded';
    });
    builder.addCase(doGetTypes.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCancelJobOrder.pending, (state) => {
      state.action = 'cancel';
      state.status = 'loading';
    });
    builder.addCase(doCancelJobOrder.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCancelJobOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllJobOrders,
  selectById: selectJobOrderById,
  selectIds: selectJobOrderIds,
} = jobOrdersAdapter.getSelectors((state) => state.jobOrder);
const { reducer: JobOrderReducer } = jobOrderSlice;
export default JobOrderReducer;
