import React, { useEffect } from 'react';
import { message as messageAnt } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../redux/slice';

const AppMessage = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.common.message);
  useEffect(() => {
    if (message) {
      messageAnt.success({
        content: message,
        onClose: () => dispatch(setMessage(null)),
      });
    }
  }, [message, setMessage]);

  return <></>;
};

export default AppMessage;
