import { Button, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import UsersTable from '../Elements/Tables/UsersTable';
import { selectAllClients } from '../../modules/client/stores/slice';
import { doGetClients } from '../../modules/client/stores/thunk';

const ClientsPage = () => {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();
  const action = useSelector((state) => state.client.action);
  const isFetching = useSelector((state) => state.client.isFetching);
  const status = useSelector((state) => state.client.status);
  const metadata = useSelector((state) => state.client.metadata);
  const clients = useSelector((state) => selectAllClients(state));

  useEffect(() => {
    setSearchValue(query.q || '');
  }, []);

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Clients/Page' },
    total: metadata.totalItems || clients.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Clients`,
  };

  useEffect(() => {
    dispatch(doGetClients({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    delete params.showSizeChanger;
    delete params.total;
    delete params.locale;
    delete params.page;
    if (updatePath) {
      navigate({
        pathname: location.pathname,
        search: queryString.stringify(params || {}, {
          skipNull: true,
        }),
      });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetClients({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => navigate(`/clients/${record.id}`)}>
          <ReactSVG className="icon" src="/icons/edit.svg" />
        </Button>
        <div className="line"></div>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };
  return (
    <Spin spinning={false}>
      <div className="Content ClientsPage">
        <div className="ContentHeader">
          <div className="HeaderTitle">CLIENTS</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Link to="/clients/new">
                <Button className="CreateButton ButtonPrimary" type="primary" size="small">
                  Create New Client
                  <ReactSVG className="icon" src="/icons/add.svg" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="orders-header-table page-header-table">
          <div className="search">
            <Input
              placeholder="Search"
              suffix={<img src="/icons/search.svg" />}
              onChange={handleChangeSearch}
              onBlur={handleSearch}
              onKeyDown={handleKeyDown}
              disabled={isFetching}
              value={searchValue}
            />
          </div>
          <div className="buttons">
            {/* <Button className="FilterButton ButtonFilter">
              Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
            </Button>
            <Button className="FilterButton ButtonFilter" onClick={() => setIsFilterOpen(!isFilterOpen)}>
              Filter
              <ReactSVG className="icon" src="/icons/filter_list.svg" />
            </Button> */}
          </div>
        </div>
        {/* {isFilterOpen && <JobOrdersFilter isFetching={false} query={query} onSubmit={handleFilter} />} */}
        <div className="ContentMain">
          <UsersTable
            dataSource={clients}
            pagination={pagination}
            withAction={withAction}
            selectedRowKeys={selectedRowKeys}
            onSelectChange={onSelectChange}
            rowKey="id"
            isFetching={false}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ClientsPage;
