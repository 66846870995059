/* eslint-disable max-len */
const Dropoff = () => {
  return `<svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M54.3077 26.7692C54.3077 41.5535 42.3228 53.5385 27.5385 53.5385C12.7543 53.5385 0.769287 41.5535 0.769287 26.7692C0.769287 11.985 12.7543 0 27.5385 0C42.3228 0 54.3077 11.985 54.3077 26.7692Z" fill="#1961E4"/>
  <circle cx="27.5385" cy="26.7692" r="17.8462" fill="white"/>
  <circle cx="27.5386" cy="26.7692" r="8.92308" fill="#1961E4"/>
  </svg>
 `;
};
export default Dropoff;
