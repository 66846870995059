import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateClient, doGetClient, doGetClients, doRemoveClient, doUpdateClient } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('clients/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('clients/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('clients/') && action.type.endsWith('/rejected');
};

const clientsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    action: null,
    orderDetail: null,
    isFetching: false,
    clientDetail: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetClients.pending, (state) => {
      state.action = 'getall';
      state.clientDetail = null;
      state.status = 'loading';
    });
    builder.addCase(doGetClients.fulfilled, (state, action) => {
      const { users, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      clientsAdapter.setAll(state, users);
    });
    builder.addCase(doGetClients.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetClient.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetClient.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.clientDetail = action.payload;
    });
    builder.addCase(doGetClient.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateClient.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateClient.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateClient.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateClient.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateClient.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateClient.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doRemoveClient.pending, (state) => {
      state.action = 'remove';
      state.status = 'loading';
    });
    builder.addCase(doRemoveClient.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doRemoveClient.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllClients,
  selectById: selectClientById,
  selectIds: selectClientds,
} = clientsAdapter.getSelectors((state) => state.client);
const { reducer: clientReducer } = clientSlice;
export default clientReducer;
