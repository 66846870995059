import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../constants';

export default async function (endpoint, opts) {
  try {
    const { headers, ...options } = opts;
    let token = Cookies.get(TOKEN_NAME) || '';
    const refreshTokenCookies = Cookies.get(REFRESH_TOKEN_NAME) || 'ex-2';
    const decodeToken = token ? jwtDecode(token) : undefined;
    if (decodeToken?.exp) {
      const expirationTime = decodeToken.exp * 1000; // Convert seconds to milliseconds
      const currentTime = Date.now();

      if (currentTime >= expirationTime) {
        const res = await axios.post(`${process.env.REACT_APP_API_URI}auth/refresh-access-token`, {
          refreshToken: refreshTokenCookies || 'example',
        });
        const newToken = res?.data?.accessToken;
        if (newToken) {
          Cookies.set(TOKEN_NAME, newToken);
          token = newToken;
        }
      }
    }
    const config = Object.assign(
      {
        url: endpoint,
        baseURL: process.env.REACT_APP_API_URI,
        headers: Object.assign(
          {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
          headers,
        ),
        timeout: 60000,
      },
      options,
    );
    const response = await axios.request(config);
    return { response: response.data || {} };
  } catch (error) {
    if (error.response) {
      return {
        error: Object.assign(error.response.data, {
          status: error.response.status || error.request?.status,
        }),
      };
    }
    if (error.request && error.request._response) {
      return { error: { message: error.request._response } };
    }
    return { error: { message: error.message } };
  }
}
