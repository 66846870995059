export function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

export function convertModelToFormData(val, formData = new FormData(), namespace = '') {
  if (typeof val !== 'undefined' && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      formData.append(namespace, JSON.stringify(val));
    } else if (typeof val === 'object' && !(val instanceof File)) {
      for (let propertyName in val) {
        // eslint-disable-next-line no-prototype-builtins
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? namespace + '[' + propertyName + ']' : propertyName,
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val ? val.toString() : null);
    }
  }
  return formData;
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hexToRgba = (hex, alpha) => {
  // Remove the hash (#) if it's present
  hex = hex.replace(/^#/, '');

  // Check if the hex string is valid
  if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error('Invalid hex color code');
  }

  // Parse the hex value into its components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Check if the alpha value is within the valid range (0 to 1)
  if (alpha < 0 || alpha > 1) {
    throw new Error('Invalid alpha value');
  }

  // Return the RGBA color as a CSS string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export async function urlToBase64(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting URL to base64:', error);
    throw error;
  }
}

export const imageMaker = (child) => {
  return 'data:image/svg+xml;charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(child)));
};

export const hhMmToHours = (timeString) => {
  const arr = timeString.split(':');
  return arr[0] * 1;
};

export const getProgressStatus = (status) => {
  let index = -1;
  if (status === 'ACCEPTED') index = 0;
  if (status === 'PICKUP_COMPLETED') index = 1;
  if (status === 'DELIVERY_COMPLETED') index = 2;
  return index;
};

export const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};
