import React from 'react';
import { connect } from 'react-redux';
import { setError } from '../../redux/slice';

export default (Component) => {
  const Container = (props) => {
    return <Component props={props} />;
  };

  function mapStateToProps(state) {
    const { error } = state.common;
    return {
      error: error,
    };
  }
  return connect(mapStateToProps, { setError })(Container);
};
