import { Col, Form, Row, Input, Button, Spin } from 'antd';
import React, { useEffect } from 'react';
import { TYPE_PAGE } from '../../constants';
import './UserForm.scss';

const initValuesForm = {
  email: '',
  password: '',
};

const UserForm = ({ handleOk, user, formUser, isFetching, typePage = TYPE_PAGE.NEW }) => {
  useEffect(() => {
    if (user) {
      formUser.setFieldsValue({ ...user });
    } else {
      formUser.resetFields();
    }
  }, [formUser, user]);

  const onFinishForm = (values) => {
    const params = {
      name: values.name,
      email: values.email,
    };
    if (values.password) {
      params.password = values.password;
    }
    handleOk && handleOk(params);
  };

  return (
    <Spin spinning={isFetching}>
      <div className="UserForm">
        <Form
          form={formUser}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          colon={false}
          onFinish={onFinishForm}
          initialValues={initValuesForm}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="User Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" placeholder="User Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]}
              >
                <Input
                  disabled={typePage === TYPE_PAGE.EDIT}
                  size="large"
                  placeholder="Email"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: !user, message: 'Required' },
                  { min: 6, message: 'Password must be minimum 6 characters.' },
                ]}
              >
                <Input.Password size="large" autoComplete="new-password" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default UserForm;
