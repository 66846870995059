import WebMercatorViewport from 'viewport-mercator-project';
import { maxBy, minBy } from 'lodash';
import { STEP_TYPE } from '../constants';

const getMinOrMax = (markers, minOrMax, latOrLng) => {
  if (minOrMax === 'max') {
    return maxBy(markers, (value) => value[latOrLng])[latOrLng];
  } else {
    return minBy(markers, (value) => value[latOrLng])[latOrLng];
  }
};

const getBounds = (markers) => {
  const maxLat = getMinOrMax(markers, 'max', 'latitude');
  const minLat = getMinOrMax(markers, 'min', 'latitude');
  const maxLng = getMinOrMax(markers, 'max', 'longitude');
  const minLng = getMinOrMax(markers, 'min', 'longitude');

  const southWest = [Number(minLng), Number(minLat)];
  const northEast = [Number(maxLng), Number(maxLat)];
  const result = [southWest, northEast];
  return result;
};

export const getMapBounds = (points, width, height) => {
  const markers_bounds = getBounds(points);
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const newViewport = new WebMercatorViewport({
    width,
    height,
  });
  const bound = newViewport.fitBounds(markers_bounds, {
    padding: 100,
    // offset: [0, -100],
  }); // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = bound;
  return { longitude, latitude, zoom: points.length === 1 ? 14 : zoom - 1 };
};

// export const getStopsOrder = (order, preStops = [], color = '606060') => {
//   const stops = [];
//   if (
//     !preStops.filter(
//       (pre) =>
//         pre.riderId === order.riderId &&
//         pre.type === STEP_TYPE.pickup &&
//         pre.lat == order.pickupAddressLat &&
//         pre.lng == order.pickupAddressLng,
//     ).length
//   ) {
//     stops.push(getStopPickupOrder(order, color));
//   }
//   stops.push(getStopDeliveryOrder(order, color));
//   return stops;
// };

// export const getStopPickupOrder = (order, color = '606060') => {
//   const stop = {
//     id: `${order.id}-pickup`,
//     orderId: order.id,
//     orderCode: order.orderId,
//     orderRef: order.orderRef,
//     name: order.pickupName,
//     phone: order.pickupPhone,
//     address: order.pickupAddress,
//     lat: order.pickupAddressLat,
//     lng: order.pickupAddressLng,
//     status: order.pickupStatus === 'COLLECTED' ? 'COMPLETED' : order.pickupStatus,
//     type: 'pickup',
//     startTime: order.pickupTimeStart,
//     endTime: order.pickupTimeEnd,
//     color: order.rider?.color || color,
//     riderName: order.rider?.user?.name,
//     riderId: order.riderId,
//     routePlanId: order.routePlanId,
//     notes: order.pickupNotes,
//     driverNotes: order.driverNotes,
//   };

//   return stop;
// };

// export const getStopDeliveryOrder = (order, color = '606060') => {
//   const stop = {
//     id: `${order.id}-delivery`,
//     orderId: order.id,
//     orderCode: order.orderId,
//     orderRef: order.orderRef,
//     name: order.deliveryName,
//     phone: order.deliveryPhone,
//     address: order.deliveryAddress,
//     lat: order.deliveryAddressLat,
//     lng: order.deliveryAddressLng,
//     status: order.status,
//     type: 'delivery',
//     startTime: order.deliveryTimeStart,
//     endTime: order.deliveryTimeEnd,
//     color: order.rider?.color || color,
//     riderName: order.rider?.user?.name,
//     notes: order.deliveryNotes,
//     riderId: order.riderId,
//     routePlanId: order.routePlanId,
//     driverNotes: order.driverNotes,
//   };
//   return stop;
// };
