import React, { createRef, useEffect, useState } from 'react';
import ReactMapGL, { Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
import './DefaultMapbox.scss';
import { decodePolyline } from './Utils';
import useRefDimensions from '../../hooks/useRefDimensions';
import { getMapBounds } from '../../utils/map';

const DefaultMapbox = ({ markers, lngLatMove, polylines, className, isControl, ...props }) => {
  const mapRef = React.useRef();
  const divRef = createRef();
  const dimensions = useRefDimensions(divRef);
  const [fitBounded, setFitBounded] = useState(false);

  const navStyle = {
    top: 0,
    left: 0,
    padding: '10px',
  };

  const [viewState, setViewState] = React.useState({
    longitude: 4.931713,
    latitude: 52.354399,
    zoom: 12,
  });

  useEffect(() => {
    if (lngLatMove?.length === 2) mapRef.current?.flyTo({ center: lngLatMove, zoom: 8 });
  }, [lngLatMove, mapRef]);

  useEffect(() => {
    if (markers?.length > 0 && dimensions.width) {
      setFitBounded(true);
      const bounds = getMapBounds(markers, dimensions.width, dimensions.height);
      setViewState((pre) => {
        return {
          ...pre,
          ...bounds,
        };
      });
    }
    // eslint-disable-next-line
  }, [dimensions, markers]);

  const showMarkers = React.useMemo(
    () =>
      markers.map((marker, index) => {
        return (
          <Marker
            key={index + 'marker'}
            longitude={marker.longitude}
            latitude={marker.latitude}
            offset={[marker.offsetX, marker.offsetY]}
            className={marker.className}
            style={marker.style}
          >
            {marker.content}
          </Marker>
        );
      }),
    [markers],
  );

  return (
    <div
      ref={divRef}
      style={{ width: '100%', height: '100%', position: 'relative' }}
      className={`DefaultMapbox ${className}`}
    >
      <ReactMapGL
        ref={mapRef}
        {...viewState}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
        onMove={(evt) => setViewState(evt.viewState)}
      >
        {showMarkers}
        {polylines?.length > 0 && (
          <Source
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: [
                ...polylines.map((po) => {
                  const polyline = po.polyline;
                  return {
                    properties: {
                      stroke: po.strokeColor,
                      strokeWeight: 6,
                      strokeOpacity: po.strokeOpacity,
                    },
                    geometry: {
                      type: 'LineString',
                      coordinates:
                        polyline && decodePolyline(polyline, 5).length > 0
                          ? decodePolyline(polyline, 5).map((c) => [c.lng, c.lat])
                          : [],
                    },
                  };
                }),
              ],
            }}
          >
            <Layer
              id="lineLayer"
              type="line"
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
              }}
              paint={{
                'line-color': ['get', 'stroke'],
                'line-width': ['get', 'strokeWeight'],
                'line-opacity': ['get', 'strokeOpacity'],
              }}
            />
          </Source>
        )}
        {props.children}
        {isControl && <NavigationControl style={navStyle} />}
      </ReactMapGL>
    </div>
  );
};

export default DefaultMapbox;
