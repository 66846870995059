import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

const BreadCrumb = ({ breadCrumbs }) => {
  const items = breadCrumbs.map((breadCrumb) => {
    return {
      title: breadCrumb.url ? <Link to={breadCrumb.url}>{breadCrumb.text}</Link> : breadCrumb.text,
    };
  });
  return <Breadcrumb className="BreadCrumb" items={items} separator={<RightOutlined />} />;
};
export default BreadCrumb;
