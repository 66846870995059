import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { doCancelJobOrder, doGetJobOrder } from '../../modules/jobOrders/stores/thunk';
import { Button, Col, Divider, Popover, Row, Spin, Steps, Tooltip, notification } from 'antd';
import BreadCrumb from '../breadcrumbs';
import FormTitleHeader from '../Header/FormTitleHeader';
import './JobOrderDetailPage.scss';
import { getProgressStatus, imageMaker, truncate } from '../../utils/common';
import Pickup from '../../Icons/pickup';
import Dropoff from '../../Icons/dropoff';
import DefaultMapbox from '../Mapbox/DefaultMapbox';
import { DAYTIME_FRONT_FORMAT, DAY_INPUT_FORMAT, ITEM_STATUSES, JOB_ORDER_STATUSES } from '../../constants';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { EllipsisOutlined } from '@ant-design/icons';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const JobOrderDetailPage = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const jobOrder = useSelector((state) => state.jobOrder.jobOrderDetail);
  const isFetching = useSelector((state) => state.jobOrder.isFetching);
  const status = useSelector((state) => state.jobOrder.status);
  const action = useSelector((state) => state.jobOrder.action);
  const [polylines, setPolylines] = useState([]);
  const [markers, setMarkers] = useState([]);
  const navigate = useNavigate();

  let { jobOrderId } = useParams();

  useEffect(() => {
    dispatch(doGetJobOrder(jobOrderId));
  }, []);

  useEffect(() => {
    if (action === 'getOne' && ['succeeded', 'failed'].includes(status) && !jobOrder) {
      navigate('/job-orders');
    }
  }, [action, status, jobOrder]);

  useEffect(() => {
    if (action === 'cancel' && ['succeeded'].includes(status)) {
      dispatch(doGetJobOrder(jobOrderId));
      api.info({
        message: 'Job Order Canceled',
        description: 'The Job Order has been successfully canceled.',
      });
    }
  }, [action, status]);

  useEffect(() => {
    if (jobOrder) {
      if (jobOrder.polyline) {
        setPolylines([{ polyline: jobOrder.polyline, strokeColor: '#1961E4', strokeWeight: '8px' }]);
        const newMarkers = [];
        if (jobOrder.pickupAddressLat) {
          newMarkers.push({
            id: 1,
            content: <img src={imageMaker(Pickup())} style={{ maxWidth: 40 }} />,
            latitude: Number(jobOrder.pickupAddressLat),
            longitude: Number(jobOrder.pickupAddressLng),
            offsetX: 0,
            offsetY: 0,
            className: 'order-marker',
          });
        }
        if (jobOrder.deliveryAddressLat) {
          newMarkers.push({
            id: 1,
            content: <img src={imageMaker(Dropoff())} style={{ maxWidth: 40 }} />,
            latitude: Number(jobOrder.deliveryAddressLat),
            longitude: Number(jobOrder.deliveryAddressLng),
            offsetX: 0,
            offsetY: 0,
            className: 'order-marker',
          });
        }
        setMarkers(newMarkers);
      }
    }
  }, [jobOrder]);

  const cancelJobOrder = async () => {
    dispatch(doCancelJobOrder(jobOrderId));
  };

  const breadCrumbs = [{ text: 'JOB ORDERS', url: '/job-orders' }, { text: jobOrderId }];

  console.log(jobOrder);
  if (!jobOrder) return <></>;
  const statusDatetime = (status) => jobOrder.logs.find((l) => l.status === status)?.createdAt;

  const itemsStatus = [
    {
      title: <div className="jobOrderStatus PICKUP_COMPLETED">PICKUP COMPLETED</div>,
      description:
        statusDatetime('PICKUP_COMPLETED') && moment(statusDatetime('PICKUP_COMPLETED')).format(DAYTIME_FRONT_FORMAT),
    },
    {
      title: <div className="jobOrderStatus DELIVERY_COMPLETED">DELIVERY COMPLETED</div>,
      description:
        statusDatetime('DELIVERY_COMPLETED') &&
        moment(statusDatetime('DELIVERY_COMPLETED')).format(DAYTIME_FRONT_FORMAT),
    },
  ];

  if (!jobOrder.driverId) {
    itemsStatus.unshift({
      title: <div className="jobOrderStatus PENDING">PENDING</div>,
      description: statusDatetime('PENDING') && moment(statusDatetime('PENDING')).format(DAYTIME_FRONT_FORMAT),
    });
  } else {
    itemsStatus.unshift({
      title: <div className="jobOrderStatus ACCEPTED">ACCEPTED</div>,
      description: statusDatetime('ACCEPTED') && moment(statusDatetime('ACCEPTED')).format(DAYTIME_FRONT_FORMAT),
    });
  }

  const content = (
    <div className="cancel-job-detail">
      <div className="action" onClick={cancelJobOrder}>
        <ReactSVG className="icon" src="/icons/cancel.svg" />
        <p>Cancel Job</p>
      </div>
    </div>
  );

  return (
    <Spin spinning={isFetching}>
      {contextHolder}
      <div className="Content JobOrderDetailPage">
        <BreadCrumb breadCrumbs={breadCrumbs} />
        <div className="wrapper">
          <div className="header">
            <FormTitleHeader title="Order Details" />
            <div className="action-header">
              <Popover placement="bottom" content={content} trigger="click">
                <EllipsisOutlined />
              </Popover>
            </div>
          </div>
          <div className="map">
            <DefaultMapbox markers={markers} className="mapbox-order-detail" polylines={polylines} />
          </div>
          <div className="order-info">
            <div className="header">
              <div className="header-left">
                <b>Pickup roof rails from HomeDepot</b>
              </div>
              <div className="header-right">
                <span className={'jobOrderStatus ' + jobOrder?.status?.toUpperCase()}>
                  <div className="circle"></div>
                  {JOB_ORDER_STATUSES[jobOrder?.status]}
                </span>
              </div>
            </div>
            <div className="cost">
              <b>Total Cost</b>
              <div className="price-wrapper">
                <div className="price">
                  <h4>$130.99</h4>
                </div>
                <Button size="large" type="link">
                  View Cost Breakdown
                  <ReactSVG className="icon" src="/icons/list_alt.svg" />
                </Button>
              </div>
            </div>
            <div className="order-time-info">
              <Row>
                <Col span={8}>
                  <div className="child">
                    <div className="child-content">
                      <label>Date</label>
                      <p>{moment(jobOrder.deliveryDate).format(DAY_INPUT_FORMAT)}</p>
                    </div>
                    <div className="divider-left"></div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="child">
                    <div className="child-content">
                      <label>Completion Time</label>
                      <p>{jobOrder.completionTime}</p>
                    </div>
                    <div className="divider-left"></div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="child">
                    <div className="child-content">
                      <label>Distance </label>
                      <p>{jobOrder.distance ? (jobOrder.distance / 1000).toFixed(2) + ' km' : '-'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="addresses">
              <div className="address">
                <div className="circle-pickup"></div>
                <p>{jobOrder.pickupAddress}</p>
              </div>
              <div className="little-circles">
                <div className="little-circle"></div>
                <div className="little-circle"></div>
                <div className="little-circle"></div>
              </div>
              <div className="address">
                <div className="circle-delivery"></div>
                <p>{jobOrder.deliveryAddress}</p>
              </div>
            </div>
            <Divider />
            <div className="order-instruction">
              <b>Instruction</b>
              <p>{jobOrder.instruction}</p>
              <b>Receipt:</b>
              <div className="files">
                {jobOrder.files?.map((file) => {
                  const type = file.key.split('.')[file.key.split('.').length - 1];
                  return (
                    <Tooltip
                      key={file.id + 'file'}
                      className="preview-wrapper"
                      placement="top"
                      title={file.key}
                      onClick={() => window.open(file.url, '_blank')}
                    >
                      {['pdf', 'csv', 'xlsx'].includes(type) && (
                        <ReactSVG className="icon" src={`/icons/${type}.svg`} />
                      )}
                      <p>{truncate(file.key, 20)}</p>
                    </Tooltip>
                  );
                })}
              </div>
            </div>
            <Divider />
            <div className="items">
              <b>Item(s) for pickup</b>
              {jobOrder.packages.length > 0 &&
                jobOrder.packages.map((pac, i) => (
                  <>
                    <div key={pac.id + 'package'} className="item">
                      <div className="item-text">
                        <p>
                          {i + 1}. {pac.name}
                        </p>
                        <p>
                          {pac.length || 'N/A'} x {pac.width || 'N/A'} x {pac.height || 'N/A'} cm |{' '}
                          {pac.weight || 'N/A'} kg | Qty: {pac.quantity || 'N/A'}
                        </p>
                      </div>
                      <span className={`item-status ${pac.status}`}>{ITEM_STATUSES[pac.status] || pac.status}</span>
                    </div>
                    {i != 0 && i !== jobOrder.packages.length - 1 && <Divider />}
                  </>
                ))}
            </div>
            <Divider />
            <div className="description">
              <b>Job Description</b>
              <p>{jobOrder.description || '-'}</p>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <FormTitleHeader title="Job Details" />
          <Divider />
          <div className="statuses-log white border-bottom">
            <div className="header">
              <div className="Info Rider">
                <label>Driver: </label>
                <div className="Text">{jobOrder.driver?.user.name || 'Unassigned'}</div>
              </div>
            </div>
            <div className="statuses">
              <Steps
                status="finish"
                current={getProgressStatus(jobOrder.status)}
                labelPlacement="vertical"
                items={itemsStatus}
              />
            </div>
          </div>
          <Divider />
          <div className="DeliveryDetail">
            <Row>
              <Col span={12}>
                <b>Proof of Delivery</b>
                <div className="photos">
                  <div className="value">
                    {jobOrder?.proofs.map((pod, index) => (
                      <img key={index} src={pod.url} crossOrigin="anonymous" />
                    ))}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <b>Customer Signature</b>
                <div className="photos signature border-left">
                  <div className="value">
                    {jobOrder?.signatures.map((signature, index) => (
                      <img key={index} src={signature.url} crossOrigin="anonymous" />
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="description">
            <b>Delivery Note</b>
            <p>{jobOrder.deliveryNote || '-'}</p>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default JobOrderDetailPage;
