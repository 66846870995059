import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { doGetProfile } from '../stores/thunk';
import { MainLayout } from '../../../components/Layout';
import isEmpty from 'lodash/isEmpty';

const Container = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.common.error);
  const [isLoaded, setIsLoaded] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (auth && auth.authenticated && !auth.user && !isLoaded) {
      console.log('run');
      setIsLoaded(true);
      dispatch(doGetProfile());
    }
  }, []);

  if (isEmpty(auth) || !auth.authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (auth && auth.authenticated && (error.statusCode === 401 || error.statusCode === 503)) {
    return <Navigate to={{ pathname: '/logout' }} />;
  }

  if (auth && auth.authenticated && !auth.user) return <Spin style={{ paddingTop: 100, width: '100%' }} />;

  return <MainLayout>{children}</MainLayout>;
};

export default Container;
