import React from 'react';
import './FormTitleHeader.scss';

const FormTitleHeader = ({ title }) => {
  return (
    <div className="FormTitleHeader">
      <h4>{title}</h4>
    </div>
  );
};

export default FormTitleHeader;
