import { Col, Form, Row, Input, Spin, Upload, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { TYPE_PAGE } from '../../constants';
import './DriverForm.scss';
import OptionalLabel from '../Elements/OptionalLabel';
import { UploadOutlined } from '@ant-design/icons';

const initValuesForm = {
  email: '',
  password: '',
};

const currentYear = new Date().getFullYear();
const years = Array.from(new Array(30), (val, index) => currentYear - index + 10);

const DriverForm = ({
  handleOk,
  driver,
  formDriver,
  isFetching,
  fileList,
  setFileList,
  typePage = TYPE_PAGE.NEW,
  vehicles,
  types,
  vehicleInspectionDocuments,
  setVehicleInspectionDocuments,
  vehicleInsurancePhotos,
  setVehicleInsurancePhotos,
  driverAbstracts,
  setDriverAbstracts,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const { Option } = Select;

  const [selectedMake, setSelectedMake] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);

  useEffect(() => {
    if (driver) {
      formDriver.setFieldsValue({ ...driver });
    } else {
      formDriver.resetFields();
    }
  }, [formDriver, driver]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return fileList;
  };

  const handleChange = (info, type) => {
    let newFileList = [...info.fileList];
    const file = newFileList.slice(-1)[0];
    const isLt5M = (file?.size || 0) / 1024 / 1024 <= 5;
    if (!isLt5M) {
      api.error({ message: 'Image must smaller than 5MB!' });
    } else {
      newFileList = newFileList.slice(-1);
      if (type === 'vehiclePhotos') {
        setFileList(newFileList);
      } else if (type === 'vehicleInspectionDocuments') {
        setVehicleInspectionDocuments(newFileList);
      } else if (type === 'vehicleInsurancePhotos') {
        setVehicleInsurancePhotos(newFileList);
      } else if (type === 'driverAbstracts') {
        setDriverAbstracts(newFileList);
      }
    }
  };

  const onFinishForm = (values) => {
    const params = { ...values, password: undefined };
    if (values.password) {
      params.password = values.password;
    }
    if (values.vehicleYear) {
      params.vehicleYear = String(values.vehicleYear);
    }
    handleOk && handleOk(params);
  };

  const handleMakeChange = (value) => {
    setSelectedMake(value);
    const selectedCar = vehicles.find((car) => car.make === value);
    setAvailableModels(selectedCar ? selectedCar.models : []);
  };

  return (
    <Spin spinning={isFetching}>
      {contextHolder}
      <div className="DriverForm">
        <Form
          form={formDriver}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          colon={false}
          onFinish={onFinishForm}
          initialValues={initValuesForm}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]}
              >
                <Input
                  disabled={typePage === TYPE_PAGE.EDIT}
                  size="large"
                  placeholder="Email"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: !driver, message: 'Required' },
                  { min: 6, message: 'Password must be minimum 6 characters.' },
                ]}
              >
                <Input.Password size="large" autoComplete="new-password" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Vehicle Make" name={'vehicleMake'}>
                <Select placeholder="Select a vehicle make" size="large" onChange={handleMakeChange}>
                  {vehicles.map((car) => (
                    <Option key={car.make} value={car.make}>
                      {car.make}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Vehicle Type" name={'vehicleType'}>
                <Select placeholder="Select a vehicle type" size="large">
                  {types.map((type) => (
                    <Option key={type.type} value={type.type}>
                      {type.displayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Vehicle Model" name="vehicleModel">
                <Select
                  placeholder={selectedMake ? 'Select a vehicle model' : 'Select a make first'}
                  size="large"
                  disabled={!selectedMake}
                >
                  {availableModels.map((model) => (
                    <Option key={model} value={model}>
                      {model}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Vehicle Year" name="vehicleYear">
                <Select placeholder="Select a year" size="large">
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<OptionalLabel title="Vehicle Photo" />}
                name="vehiclePhotos"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  accept=".png, .jpg, .jpeg"
                  showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                  }}
                  customRequest={async ({ onSuccess, onError }) => {
                    try {
                      onSuccess('ok');
                    } catch (e) {
                      console.log(e);
                      onError();
                    }
                  }}
                  onRemove={() => setFileList([])}
                  listType="picture-card"
                  fileList={fileList}
                  onChange={(info) => handleChange(info, 'vehiclePhotos')}
                >
                  <p className="upload-text">Drag and drop to upload or</p>
                  <p className="upload-drag-icon">
                    Browse
                    <UploadOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<OptionalLabel title="Vehicle Insurance Photo" />}
                name="vehicleInsurancePhotos"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  accept=".png, .jpg, .jpeg"
                  showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                  }}
                  customRequest={async ({ onSuccess, onError }) => {
                    try {
                      onSuccess('ok');
                    } catch (e) {
                      console.log(e);
                      onError();
                    }
                  }}
                  onRemove={() => setVehicleInsurancePhotos([])}
                  listType="picture-card"
                  fileList={vehicleInsurancePhotos}
                  onChange={(info) => handleChange(info, 'vehicleInsurancePhotos')}
                >
                  <p className="upload-text">Drag and drop to upload or</p>
                  <p className="upload-drag-icon">
                    Browse
                    <UploadOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<OptionalLabel title="Driver Abstract" />}
                name="driverAbstracts"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  accept=".pdf, .pdfx, .odt, .doc, .docx"
                  showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                  }}
                  customRequest={async ({ onSuccess, onError }) => {
                    try {
                      onSuccess('ok');
                    } catch (e) {
                      console.log(e);
                      onError();
                    }
                  }}
                  onRemove={() => setDriverAbstracts([])}
                  listType="picture-card"
                  fileList={driverAbstracts}
                  onChange={(info) => handleChange(info, 'driverAbstracts')}
                >
                  <p className="upload-text">Drag and drop to upload or</p>
                  <p className="upload-drag-icon">
                    Browse
                    <UploadOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<OptionalLabel title="Vehicle Insurance Document" />}
                name="vehicleInsuranceDocuments"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  accept=".pdf, .pdfx, .odt, .doc, .docx"
                  showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: true,
                  }}
                  customRequest={async ({ onSuccess, onError }) => {
                    try {
                      onSuccess('ok');
                    } catch (e) {
                      console.log(e);
                      onError();
                    }
                  }}
                  onRemove={() => setVehicleInspectionDocuments([])}
                  listType="picture-card"
                  fileList={vehicleInspectionDocuments}
                  onChange={(info) => handleChange(info, 'vehicleInspectionDocuments')}
                >
                  <p className="upload-text">Drag and drop to upload or</p>
                  <p className="upload-drag-icon">
                    Browse
                    <UploadOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default DriverForm;
