import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import AppError from './AppError';
import AppMessage from './AppMessage';
import { Navigate, Route, Routes } from 'react-router-dom';
import JobOrders from '../components/Pages/JobOrders';
import SignInPage from '../components/Pages/SignIn';
import { PrivateRoute } from '../modules/auth/containers';
import LogoutPage from '../components/Pages/Logout';
import UsersPage from '../components/Pages/Users';
import UserPage from '../components/Pages/User';
import CreateJobOrderPage from '../components/Pages/CreateJobOrder';
import JobOrderDetailPage from '../components/Pages/JobOrderDetail';
import ClientsPage from '../components/Pages/Clients';
import ClientPage from '../components/Pages/Client';
import DriversPage from '../components/Pages/Drivers';
import DriverPage from '../components/Pages/Driver';

const App = () => {
  const globalLoading = useSelector((state) => state.common.globalLoading);
  return (
    <Spin spinning={globalLoading} wrapperClassName="wrap-spin">
      <AppError />
      <AppMessage />
      <Routes>
        <Route
          path="/job-orders"
          element={
            <PrivateRoute>
              <JobOrders />
            </PrivateRoute>
          }
        />
        <Route
          path="/job-orders/create"
          element={
            <PrivateRoute>
              <CreateJobOrderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/job-orders/:jobOrderId"
          element={
            <PrivateRoute>
              <JobOrderDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/drivers"
          element={
            <PrivateRoute>
              <DriversPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/drivers/create"
          element={
            <PrivateRoute>
              <DriverPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/drivers/:id"
          element={
            <PrivateRoute>
              <DriverPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <ClientsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients/new"
          element={
            <PrivateRoute>
              <ClientPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients/:id"
          element={
            <PrivateRoute>
              <ClientPage />
            </PrivateRoute>
          }
        />
        <Route exact path="/logout" element={<LogoutPage />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </Spin>
  );
};

function PageNotFound() {
  return <Navigate to="/job-orders" replace />;
}

export default App;
