export const APP_NAME = 'proquip-jobs';
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || '_pro_session';
export const DRAFT_NAME = '_pro_draft_cookie';
export const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_TOKEN_NAME || '_refresh_pro_session';

export const TIME_FORMAT = 'HH:mm';
export const TIME_INPUT_FORMAT = 'hh:mm A';
export const DAYTIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DAY_FORMAT = 'DD/MM/YYYY';
export const DAY_INPUT_FORMAT = 'MMM D, YYYY';
export const DAYTIME_FRONT_FORMAT = 'MMM D, YYYY HH:mm';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';

export const PAGE_SIZE = 15;

export const PAGE_SIZE_OPTIONS = [PAGE_SIZE, 50, 100];

export const TYPE_PAGE = {
  NEW: 'new',
  EDIT: 'edit',
  DRAFT: 'draft',
};

export const DAY_NAME = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};
export const JOB_ORDER_STATUSES = {
  ERROR: 'Error',
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  PICKUP_COMPLETED: 'Pickup Completed',
  DELIVERY_COMPLETED: 'Delivery Completed',
  CANCELED: 'Canceled',
};

export const ITEM_STATUSES = {
  ERROR: 'Error',
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  PICKUP_COMPLETED: 'PICKUP COMPLETED',
  PICKUP_FAILED: 'PICKUP FAILED',
  COLLECTED: 'COLLECTED',
  COMPLETED: 'COMPLETED',
};

export const COLOR_DEFAULT = '7732EF';

export const ONE_HOUR_PX_DEFAULT = 100;

export const COLORS = [
  'ed8f8f',
  'f284a8',
  'c788d2',
  'aa92d6',
  '959ed5',
  '85c3f8',
  '76cef9',
  '75c4bc',

  'cd2929',
  'd21a55',
  '8321a0',
  '532fa8',
  '1c7de1',
  '0790e1',
  '00a2b9',
  '007e70',

  'ae1a1a',
  '7d1046',
  '411481',
  '2b1a87',
  '0f3e97',
  '044d90',
  '005559',
  '004438',

  '9bd09d',
  'bddd9b',
  'e2eb91',
  'fff392',
  'ffc575',
  'ffa186',
  'd1c5c1',
  'c8d2d7',

  '3b963e',
  '71aa3a',
  'b8c32d',
  'fdd22f',
  'fa8100',
  'f2471c',
  '826358',
  '859aa4',

  '1a531d',
  '2d5e1c',
  '776c17',
  'f37417',
  'e24700',
  'b72f0f',
  '533830',
  '3c5059',
];

export const OLD_COLORS = [
  '00C8B4',
  '00E509',
  '9E69E0',
  '9FDC70',
  '23A9F6',
  '83C3FF',
  '641EFD',
  '669A3D',
  '748BFF',
  'C64EFF',
  'DC70C4',
  'F58585',
  'FF3535',
  'FF9365',
  'FFAB00',
  '606060',
  '7732EF',
];
