import request from '../../helper/request';

export function signIn(params) {
  return request('/auth/login', {
    method: 'POST',
    data: params,
  });
}

export function getProfile() {
  return request('/auth/profile', {
    method: 'GET',
  });
}

export function forgotPassword(params) {
  return request('/auth/reset-password', {
    method: 'POST',
    data: params,
  });
}

export function resetPassword(params) {
  return request('/auth/confirm-reset', {
    method: 'POST',
    data: params,
  });
}
