import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateUser, doGetUser, doGetUsers, doRemoveUser, doUpdateUser } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('users/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('users/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('users/') && action.type.endsWith('/rejected');
};

const usersAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    action: null,
    orderDetail: null,
    isFetching: false,
    userDetail: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetUsers.pending, (state) => {
      state.action = 'getall';
      state.userDetail = null;
      state.status = 'loading';
    });
    builder.addCase(doGetUsers.fulfilled, (state, action) => {
      const { users, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      usersAdapter.setAll(state, users);
    });
    builder.addCase(doGetUsers.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetUser.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetUser.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.userDetail = action.payload;
    });
    builder.addCase(doGetUser.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateUser.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateUser.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateUser.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateUser.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateUser.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateUser.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doRemoveUser.pending, (state) => {
      state.action = 'remove';
      state.status = 'loading';
    });
    builder.addCase(doRemoveUser.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doRemoveUser.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors((state) => state.user);
const { reducer: userReducer } = userSlice;
export default userReducer;
