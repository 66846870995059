import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DAYTIME_FORMAT } from '../../../constants';

const UsersTable = ({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) => {
  const columns = [
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 160,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      fixed: 'right',
      width: 100,
      key: 6,
      render: (isActive) => {
        const status = isActive ? 'Active' : 'Inactive';
        return <span className={'status users ' + status.toLowerCase()}>{status}</span>;
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-users table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        scroll={{ x: 1200 }}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        {...props}
      />
    </div>
  );
};

export default UsersTable;
