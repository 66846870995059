import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetDrivers = createAsyncThunk('drivers/doGetDrivers', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getDrivers(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetDriver = createAsyncThunk('drivers/doGetDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getDriver(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doCreateDriver = createAsyncThunk('drivers/doCreateDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.createDriver(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doUpdateDriver = createAsyncThunk('drivers/doUpdateDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const id = params.id;
    delete params.id;
    const { response, error } = await api.updateDriver(params, id);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

// export const doRemoveClient = createAsyncThunk('drivers/doRemoveClient', async (params, thunkAPI) => {
//   try {
//     thunkAPI.dispatch(setGlobalLoading(true));
//     const id = params.id;
//     const { response, error } = await api.removeClient(id);
//     thunkAPI.dispatch(setGlobalLoading(false));
//     return error ? thunkAPI.rejectWithValue(error) : response;
//   } catch (e) {
//     thunkAPI.rejectWithValue(e);
//     throw e;
//   }
// });
