import request from '../../helper/request';

export function getJobOrders(params) {
  return request('/job-orders', {
    method: 'GET',
    params: params,
  });
}

export function getJobOrder(orderId) {
  return request('/job-orders/' + orderId, {
    method: 'GET',
  });
}

export function createJobOrder(params) {
  return request('/job-orders', {
    method: 'POST',
    data: params,
  });
}

export function getDirections(params) {
  return request('/common/shared-directions', {
    method: 'POST',
    data: params,
  });
}

export function getTypes() {
  return request('/job-orders/types', {
    method: 'GET',
  });
}

export function cancelJobOrder(orderId) {
  return request('/job-orders/' + orderId + '/cancel', {
    method: 'POST',
  });
}
