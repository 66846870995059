import React, { useEffect } from 'react';
import { Form, Button, Row, Col, Select, Spin, DatePicker } from 'antd';
import { ReactSVG } from 'react-svg';
import { DATE_BACKEND_FORMAT, DAY_FORMAT, JOB_ORDER_STATUSES } from '../../../constants';
import dayjs from 'dayjs';

const JobOrdersFilter = ({ isFetching, query, onSubmit }) => {
  const [form] = Form.useForm();

  const statusField = 'status';
  const statusValue = query[statusField];

  const deliveryDateFromField = 'deliveryDateFrom';
  const deliveryDateFromValue = query[deliveryDateFromField];

  const deliveryDateToField = 'deliveryDateTo';
  const deliveryDateToValue = query[deliveryDateToField];

  const customerIdField = 'customerId';
  const customerIdValue = query[customerIdField];

  const driverIdField = 'driverId';
  const driverIdValue = query[driverIdField];

  const filterOption = (input, option = null) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    form.setFieldsValue(initialValues());
  }, [query]);

  const initialValues = () => {
    const values = {};
    values[statusField] = statusValue || '';
    values['deliveryDate'] = [
      deliveryDateFromValue ? dayjs(deliveryDateFromValue, DATE_BACKEND_FORMAT) : null,
      deliveryDateToValue ? dayjs(deliveryDateToValue, DATE_BACKEND_FORMAT) : null,
    ];
    values[customerIdField] = customerIdValue ? Number(customerIdValue) : '';
    values[driverIdField] = driverIdValue ? Number(driverIdValue) : '';
    return values;
  };

  const handleSubmit = (values) => {
    const params = {};
    const deliveryDate = values['deliveryDate'] || [];
    params[statusField] = values[statusField] || undefined;
    params[deliveryDateFromField] = deliveryDate[0] ? deliveryDate[0].format(DATE_BACKEND_FORMAT) : undefined;
    params[deliveryDateToField] = deliveryDate[1] ? deliveryDate[1].format(DATE_BACKEND_FORMAT) : undefined;
    // params[customerIdField] = values[customerIdField] || '';
    // params[driverIdValue] = values[driverIdValue] || '';
    onSubmit({ ...query, ...params, current: 1 }, true);
  };

  const handleResetButton = () => {
    onSubmit({}, true);
    form.resetFields();
  };

  return (
    <Spin spinning={isFetching}>
      <div className="CommonFilter OrdersFilter">
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues()}
          className="FilterForm OrdersFilterForm"
          layout="vertical"
        >
          <div className="FilterFormHeader">
            <h4>Filter</h4>
            <div className="actions">
              <Button size="small" type="text" onClick={handleResetButton}>
                Clear
                <ReactSVG className="icon" src="/icons/clear_all.svg" />
              </Button>
              <Button className="apply" size="small" type="link" htmlType="submit">
                Apply
                <ReactSVG className="icon" src="/icons/done.svg" />
              </Button>
            </div>
          </div>
          <div className="FilterFormContent">
            <Row gutter={15}>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Status" name={statusField}>
                  <Select
                    className="select-small"
                    options={[
                      { label: 'ALL', value: '' },
                      ...Object.keys(JOB_ORDER_STATUSES).map((key) => ({ label: JOB_ORDER_STATUSES[key], value: key })),
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Delivery Date" name="deliveryDate">
                  <DatePicker.RangePicker format={DAY_FORMAT} placeholder={['From', 'To']} allowEmpty={[true, true]} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Customer" name={customerIdField}>
                  <Select
                    className="select-small"
                    showSearch
                    filterOption={filterOption}
                    options={[{ label: 'ALL', value: '' }]}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Driver" name={driverIdField}>
                  <Select
                    className="select-small"
                    showSearch
                    filterOption={filterOption}
                    options={[{ label: 'ALL', value: '' }]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default JobOrdersFilter;
