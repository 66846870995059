/* eslint-disable max-len */
const Pickup = () => {
  return `<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M53.9999 26.7692C53.9999 41.5535 42.0149 53.5385 27.2307 53.5385C12.4464 53.5385 0.461426 41.5535 0.461426 26.7692C0.461426 11.985 12.4464 0 27.2307 0C42.0149 0 53.9999 11.985 53.9999 26.7692Z" fill="#1961E4"/>
  <circle cx="27.2305" cy="26.7692" r="22.3077" fill="white"/>
  <circle cx="27.2307" cy="26.7692" r="20.0769" fill="#1961E4"/>
  <g clip-path="url(#clip0_1086_2708)">
  <path d="M29.9075 20.0769L29.6398 18.7385C29.5394 18.2254 29.0821 17.8462 28.5467 17.8462H20.5382C19.9248 17.8462 19.4229 18.3481 19.4229 18.9615V35.6923C19.4229 36.3058 19.9248 36.8077 20.5382 36.8077C21.1517 36.8077 21.6536 36.3058 21.6536 35.6923V29H27.8998L28.1675 30.3385C28.2679 30.8627 28.7252 31.2308 29.2605 31.2308H35.0382C35.6517 31.2308 36.1536 30.7289 36.1536 30.1154V21.1923C36.1536 20.5789 35.6517 20.0769 35.0382 20.0769H29.9075Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_1086_2708">
  <rect width="26.7692" height="26.7692" fill="white" transform="translate(13.8459 13.3846)"/>
  </clipPath>
  </defs>
  </svg>
  `;
};
export default Pickup;
