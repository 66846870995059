import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { REFRESH_TOKEN_NAME, TOKEN_NAME } from '../../../constants';
import { doForgotPassword, doGetProfile, doResetPassword, doSignIn } from './thunk';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: false,
    authenticated: !!Cookies.get(TOKEN_NAME) && !!Cookies.get(REFRESH_TOKEN_NAME),
  },
  reducers: {
    doLogout: (state) => {
      Cookies.remove(TOKEN_NAME);
      Cookies.remove(REFRESH_TOKEN_NAME);
      state.authenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doSignIn.fulfilled, (state, action) => {
      const token = action.payload?.accessToken;
      const refreshToken = action.payload?.refreshToken;
      if (token) {
        Cookies.set(TOKEN_NAME, token);
        Cookies.set(REFRESH_TOKEN_NAME, refreshToken);
        state.authenticated = true;
      }
    });
    builder.addCase(doGetProfile.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetProfile.fulfilled, (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    });
    builder.addCase(doGetProfile.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doResetPassword.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doResetPassword.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doResetPassword.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doForgotPassword.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doForgotPassword.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doForgotPassword.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { actions, reducer: authReducer } = authSlice;
export const { doLogout } = actions;
export default authReducer;
