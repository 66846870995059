import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DAYTIME_FORMAT, DAY_FORMAT, JOB_ORDER_STATUSES } from '../../../constants';

const JobOrdersTable = ({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) => {
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Delivery Date',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 150,
      render: (value) => {
        return value ? moment(value).format(DAY_FORMAT) : '';
      },
    },
    {
      title: 'Client',
      key: 'Client Name',
      render: (row) => {
        return row?.user?.name || '';
      },
    },
    {
      title: 'Pickup Address',
      dataIndex: 'pickupAddress',
      key: 'pickupAddress',
    },
    {
      title: 'Delivery Address',
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 160,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 170,
      fixed: 'right',
      render(status) {
        return (
          <span className={'jobOrderStatus ' + status.toUpperCase()}>
            <div className="circle"></div>
            {JOB_ORDER_STATUSES[status]}
          </span>
        );
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-job-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        scroll={{ x: 2200 }}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        {...props}
      />
    </div>
  );
};

export default JobOrdersTable;
