import React from 'react';
import { Layout } from 'antd';

import MainHeader from '../Header/Main';
import './Main.scss';

const { Content } = Layout;

const MainLayout = ({ children }) => {
  return (
    <Layout className="MainLayout">
      <MainHeader />
      <Content className="site-layout" style={{ marginTop: 52 }}>
        {children}
      </Content>
    </Layout>
  );
};

export default MainLayout;
