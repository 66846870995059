import { Button, Form, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../breadcrumbs';
import FormTitleHeader from '../Header/FormTitleHeader';
import UserForm from '../Forms/UserForm';
import './Client.scss';
import { doCreateClient, doGetClient, doUpdateClient } from '../../modules/client/stores/thunk';

const ClientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formUser] = Form.useForm();
  let { id } = useParams();
  const action = useSelector((state) => state.client.action);
  const isFetching = useSelector((state) => state.client.isFetching);
  const status = useSelector((state) => state.client.status);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const breadCrumbs = [{ text: 'CLIENTS', url: '/clients' }, { text: id ? 'UPDATE CLIENT' : 'CREATE NEW CLIENT' }];

  useEffect(() => {
    if (['create', 'update'].includes(action) && status === 'succeeded') {
      navigate('/clients');
    }
  }, [isFetching]);

  useEffect(() => {
    if (id) {
      dispatch(doGetClient(id));
    }
  }, [id]);

  useEffect(() => {
    if (clientDetail) {
      formUser.setFieldsValue({ ...clientDetail });
    } else {
      formUser.resetFields();
    }
  }, [clientDetail]);

  const handleSubmit = (values) => {
    if (clientDetail) {
      dispatch(doUpdateClient({ ...values, id: clientDetail.id }));
    } else {
      dispatch(doCreateClient(values));
    }
  };

  const clickForm = () => {
    formUser.submit();
  };

  return (
    <Spin spinning={isFetching}>
      <div className="Content ClientPage">
        <BreadCrumb breadCrumbs={breadCrumbs} />

        <div className="FormContent">
          <FormTitleHeader title={id ? 'Update User' : 'Create New User'} />
          <div className="CompanyInfo">
            <UserForm user={clientDetail} isFetching={isFetching} handleOk={handleSubmit} formUser={formUser} />
          </div>
          <div className="FormFooter">
            <Link to="/clients">
              <Button size="large" className="ButtonCancel">
                Cancel
              </Button>
            </Link>
            <Button size="large" className="CreateButton ButtonPrimary" type="primary" onClick={clickForm}>
              {id ? 'Update' : 'Create'}
              <ReactSVG className="icon" src="/icons/done.svg" />
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ClientPage;
