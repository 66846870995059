import { Button, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../breadcrumbs';
import FormTitleHeader from '../Header/FormTitleHeader';
import './Driver.scss';
import { doCreateDriver, doGetDriver, doUpdateDriver } from '../../modules/driver/stores/thunk';
import DriverForm from '../Forms/DriverForm';
import requestFormData from '../../helper/requestFormData';
import { doGetTypes } from '../../modules/jobOrders/stores/thunk';

const DriverPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formDriver] = Form.useForm();
  let { id } = useParams();
  const action = useSelector((state) => state.driver.action);
  const isFetching = useSelector((state) => state.driver.isFetching);
  const vehicles = useSelector((state) => state.jobOrder.vehicles);
  const types = useSelector((state) => state.jobOrder.types);
  const status = useSelector((state) => state.driver.status);
  const driverDetail = useSelector((state) => state.driver.driverDetail);
  const breadCrumbs = [{ text: 'DRIVERS', url: '/drivers' }, { text: id ? 'UPDATE DRIVER' : 'CREATE NEW DRIVER' }];
  const [fileList, setFileList] = useState([]);
  const [driverAbstracts, setDriverAbstracts] = useState([]);
  const [vehicleInspectionDocuments, setVehicleInspectionDocuments] = useState([]);
  const [vehicleInsurancePhotos, setVehicleInsurancePhotos] = useState([]);
  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (['create', 'update'].includes(action) && status === 'succeeded') {
      navigate('/drivers');
    }
  }, [isFetching]);

  useEffect(() => {
    if (['getOne'].includes(action) && status === 'failed') {
      navigate('/drivers');
    }
  }, [isFetching]);

  useEffect(() => {
    if (id) {
      dispatch(doGetDriver(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(doGetTypes());
  }, []);

  useEffect(() => {
    if (driverDetail) {
      setFileList(driverDetail?.vehiclePhotos.map((file) => ({ ...file, uid: file.id, thumbUrl: file.url })) || []);
      setDriverAbstracts(driverDetail?.driverAbstracts.map((file) => ({ ...file, uid: file.id })) || []);
      setVehicleInsurancePhotos(
        driverDetail?.vehicleInsurancePhotos.map((file) => ({ ...file, uid: file.id, thumbUrl: file.url })) || [],
      );
      setVehicleInspectionDocuments(
        driverDetail?.vehicleInspectionDocuments.map((file) => ({ ...file, uid: file.id })) || [],
      );
      formDriver.setFieldsValue({ ...driverDetail, ...driverDetail.user });
    } else {
      formDriver.resetFields();
    }
  }, [driverDetail]);

  const handleSubmit = async (values) => {
    try {
      // Handle file uploads for vehiclePhotos
      const uploadFile = async (file) => {
        if (!file) return undefined;
        setIsUpload(true);
        const fmData = new FormData();
        fmData.append('file', file);
        const { response: fileRs } = await requestFormData('/common/shared-upload', {
          method: 'POST',
          data: fmData,
        });
        return fileRs ? [fileRs.id] : [];
      };

      const vehiclePhotosFile = fileList?.length > 0 ? fileList[0].originFileObj : undefined;
      const vehiclePhotosIds = await uploadFile(vehiclePhotosFile);
      delete values.vehiclePhotos;

      // Handle file uploads for vehicleInsurancePhotos
      const vehicleInsurancePhotosFile =
        vehicleInsurancePhotos?.length > 0 && vehicleInsurancePhotos[0].originFileObj
          ? vehicleInsurancePhotos[0].originFileObj
          : undefined;
      const vehicleInsurancePhotosIds = await uploadFile(vehicleInsurancePhotosFile);
      delete values.vehicleInsurancePhotos;

      // Handle file uploads for driverAbstracts
      const driverAbstractsFile =
        driverAbstracts?.length > 0 && driverAbstracts[0].originFileObj ? driverAbstracts[0].originFileObj : undefined;
      const driverAbstractsIds = await uploadFile(driverAbstractsFile);
      delete values.driverAbstracts;

      // Handle file uploads for vehicleInspectionDocuments
      const vehicleInspectionDocumentsFile =
        vehicleInspectionDocuments?.length > 0 && vehicleInspectionDocuments[0].originFileObj
          ? vehicleInspectionDocuments[0].originFileObj
          : undefined;
      const vehicleInspectionDocumentsIds = await uploadFile(vehicleInspectionDocumentsFile);
      delete values.vehicleInspectionDocuments;

      if (driverDetail) {
        // If updating existing driver
        dispatch(
          doUpdateDriver({
            ...values,
            id: driverDetail.id,
            vehiclePhotoIds: vehiclePhotosIds,
            vehicleInsurancePhotoIds: vehicleInsurancePhotosIds,
            driverAbstractIds: driverAbstractsIds,
            vehicleInspectionDocumentIds: vehicleInspectionDocumentsIds,
          }),
        );
      } else {
        // If creating a new driver
        dispatch(
          doCreateDriver({
            ...values,
            vehiclePhotoIds: vehiclePhotosIds,
            vehicleInsurancePhotoIds: vehicleInsurancePhotosIds,
            driverAbstractIds: driverAbstractsIds,
            vehicleInspectionDocumentIds: vehicleInspectionDocumentsIds,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (isUpload) setIsUpload(false);
    }
  };

  const clickForm = () => {
    formDriver.submit();
  };

  return (
    <Spin spinning={isFetching || isUpload}>
      <div className="Content DriverPage">
        <BreadCrumb breadCrumbs={breadCrumbs} />

        <div className="FormContent">
          <FormTitleHeader title={id ? 'Update Driver' : 'Create New Driver'} />
          <div className="CompanyInfo">
            <DriverForm
              driver={driverDetail}
              isFetching={isFetching}
              handleOk={handleSubmit}
              formDriver={formDriver}
              fileList={fileList}
              vehicles={vehicles}
              types={types}
              setFileList={setFileList}
              driverAbstracts={driverAbstracts}
              setDriverAbstracts={setDriverAbstracts}
              vehicleInspectionDocuments={vehicleInspectionDocuments}
              setVehicleInspectionDocuments={setVehicleInspectionDocuments}
              vehicleInsurancePhotos={vehicleInsurancePhotos}
              setVehicleInsurancePhotos={setVehicleInsurancePhotos}
            />
          </div>
          <div className="FormFooter">
            <Link to="/clients">
              <Button size="large" className="ButtonCancel">
                Cancel
              </Button>
            </Link>
            <Button size="large" className="CreateButton ButtonPrimary" type="primary" onClick={clickForm}>
              {id ? 'Update' : 'Create'}
              <ReactSVG className="icon" src="/icons/done.svg" />
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DriverPage;
