import { forEach, find, remove } from 'lodash';

export const getRiderIds = (routes, riderIdsT, riderId) => {
  const riderIds = [...riderIdsT];
  if (riderIds.includes(riderId)) {
    remove(riderIds, (item) => item === riderId);
    if (riderId === 'all-riders') {
      remove(riderIds, (item) => item !== 'unscheduled');
    } else if (riderId !== 'unscheduled' && riderIds.includes('all-riders')) {
      remove(riderIds, (item) => item === 'all-riders');
    }
  } else {
    riderIds.push(riderId);
    if (riderId === 'all-riders') {
      forEach(routes, (route) => riderIds.push(route.riderId));
    } else if (riderIds.filter((d) => d !== 'unscheduled').length === routes?.length) {
      riderIds.push('all-riders');
    }
  }
  return riderIds;
};

export const addPolylines = (routes) => {
  const newRoutes = [];
  forEach(routes, (route) => {
    const newRoute = { ...route };
    const polyline = newRoute.polyline;
    if (polyline) {
      const points = decodePolyline(polyline, 5);
      const pathCoordinate = points.map((x) => {
        return { lat: x[0], lng: x[1] };
      });
      newRoute.pathCoordinate = pathCoordinate;
    }
    newRoutes.push(newRoute);
  });
  return newRoutes;
};

export const getStopLeft = (stopClicked, stops) => {
  if (stopClicked && stops?.length) {
    if (stopClicked.type === 'pickup') {
      const stop = find(stops, (s) => s.orderId === stopClicked.orderId && s.type.toLowerCase() === 'delivery');
      return stop;
    } else {
      const stop = find(stops, (s) => s.orderId === stopClicked.orderId && s.type.toLowerCase() === 'pickup');
      return stop;
    }
  } else {
    return null;
  }
};

export function decodePolyline(t, e) {
  let n;
  let o;
  let u;
  let l;
  let r;
  let d;
  let h;
  let i;
  let a;
  let c;

  for (u = 0, l = 0, r = 0, d = [], h = 0, i = 0, a = null, c = Math.pow(10, e || 5); u < t.length; ) {
    a = null;
    h = 0;
    i = 0;
    do {
      a = t.charCodeAt(u++) - 63;
      i |= (31 & a) << h;
      h += 5;
    } while (a >= 32);
    n = 1 & i ? ~(i >> 1) : i >> 1;
    h = i = 0;
    do {
      a = t.charCodeAt(u++) - 63;
      i |= (31 & a) << h;
      h += 5;
    } while (a >= 32);
    o = 1 & i ? ~(i >> 1) : i >> 1;
    l += n;
    r += o;
    d.push([l / c, r / c]);
  }

  return d.map((dd) => ({ lat: dd[0], lng: dd[1] }));
}

export const convertToMinutes = (time) => {
  if (!time && typeof time !== 'string') return 0;
  const timeSplit = time.split(':');
  const hour = timeSplit[0];
  const minute = timeSplit[1];
  return parseInt(hour) * 60 + parseInt(minute);
};

export const getFirstStartAndLastEnd = (routes) => {
  if (routes.length === 0) return [0, 0];

  const firstRoute = routes[0];
  let returnStart = firstRoute.timeStart ?? '00:00';
  let returnEnd = firstRoute.timeEnd;
  let firstStart = convertToMinutes(returnStart);
  let lastEnd = convertToMinutes(returnEnd);

  forEach(routes, (route) => {
    const shiftStart = route.timeStart ?? '00:00';
    const minuteStartAt = convertToMinutes(shiftStart);
    const minuteEndAt = convertToMinutes(route.timeEnd);
    if (minuteStartAt < firstStart) {
      returnStart = shiftStart;
      firstStart = minuteStartAt;
    }

    if (lastEnd < minuteEndAt) {
      returnEnd = route.timeEnd;
      lastEnd = minuteStartAt;
    }
  });
  return [returnStart, returnEnd];
};

export const getListStyle = (dragging, focus, disabled) => {
  const style = {};
  if (dragging) {
    style.border = '1px dashed #b5b5b5';
  }
  if (focus) {
    style.border = '1px dashed #212121';
  }
  if (disabled) {
    style.border = 'none';
    style.cursor = 'no-drop';
  }
  return style;
};

export const getWidthChart = (start, end, one_hour_px) => {
  const duration = durationMinutes(start, end);
  return convertMinutesToPixel(duration, one_hour_px);
};

export const durationMinutes = (start, end) => {
  if (!start || !end || typeof start !== 'string' || typeof end !== 'string') return 0;
  return convertToMinutes(end) - convertToMinutes(start);
};

export const convertMinutesToPixel = (minutes, one_hour_px) => {
  // 1 hour = 98px =>  60 minutes = 98px
  if (minutes === 0) return 0;
  return (minutes * one_hour_px) / 60;
};

export const convertToInt = (time) => {
  if (!time) return -1;
  const times = time.split(':');
  return parseInt(times[0]) * 60 + parseInt(times[1]);
};

export const calculatePixelBetweenStops = (stops, route, one_hour_px) => {
  if (stops.length === 0) return stops;
  let tmpStop = { ...stops[0] };
  const returnStops = stops.map((stop, index) => {
    if (index === 0) {
      const width = getWidthChart(route.timeStart, stop.arrivalTime, one_hour_px);
      tmpStop = { ...stop };
      return {
        ...stop,
        index,
        marginLeft: width,
      };
    }
    const width = getWidthChart(tmpStop.finishTime, stop.arrivalTime, one_hour_px);
    tmpStop = { ...stop };
    return {
      ...stop,
      index,
      marginLeft: width,
    };
  });
  return returnStops;
};
