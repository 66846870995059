import request from '../../helper/request';

export function getClients(params) {
  return request('/clients', {
    method: 'GET',
    params: params,
  });
}

export function getClient(id) {
  return request('/clients/' + id, {
    method: 'GET',
  });
}

export function createClient(params) {
  return request('/clients', {
    method: 'POST',
    data: params,
  });
}

export function updateClient(params, id) {
  return request('/clients/' + id, {
    method: 'PATCH',
    data: params,
  });
}

export function removeClient(id) {
  return request('/clients/' + id, {
    method: 'DELETE',
  });
}
