import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Steps,
  TimePicker,
  Upload,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import BreadCrumb from '../breadcrumbs';
import FormTitleHeader from '../Header/FormTitleHeader';
import './CreateJobOrder.scss';
import { DATE_BACKEND_FORMAT, DAY_INPUT_FORMAT, DRAFT_NAME, TIME_FORMAT, TIME_INPUT_FORMAT } from '../../constants';
import AddressInput from '../../components/Inputs/AddressInput';
import DefaultMapbox from '../Mapbox/DefaultMapbox';
import { ReactSVG } from 'react-svg';
import { EllipsisOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { imageMaker } from '../../utils/common';
import Pickup from '../../Icons/pickup';
import Dropoff from '../../Icons/dropoff';
import { doCreateJobOrder, doGetDirections, doGetTypes } from '../../modules/jobOrders/stores/thunk';
import requestFormData from '../../helper/requestFormData';

const steps = [
  {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  },
];

const CreateJobOrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [dataForm, setDataForm] = useState();
  const [fileList, setFileList] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [pickUpLocation, setPickupLocation] = useState();
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [api, contextHolder] = notification.useNotification();
  const isFetching = useSelector((state) => state.jobOrder.isFetching);
  const directions = useSelector((state) => state.jobOrder.directions);
  const types = useSelector((state) => state.jobOrder.types);
  const action = useSelector((state) => state.jobOrder.action);
  const status = useSelector((state) => state.jobOrder.status);
  const breadCrumbs = [{ text: 'JOB ORDERS', url: '/job-orders' }, { text: 'CREATE NEW JOB ORDER' }];

  useEffect(() => {
    dispatch(doGetTypes());
  }, []);

  useEffect(() => {
    const dataCookie = Cookies.get(DRAFT_NAME) ? JSON.parse(Cookies.get(DRAFT_NAME)) : undefined;
    if (!isEmpty(dataCookie)) {
      const deliveryDate = dataCookie?.data?.deliveryDate ? dayjs(dataCookie?.data?.deliveryDate) : undefined;
      const pickupTime = dataCookie?.data?.pickupTime ? dayjs(dataCookie?.data?.pickupTime) : undefined;
      const deliveryWindow =
        dataCookie?.data?.deliveryWindow?.length > 0
          ? dataCookie?.data?.deliveryWindow.map((item) => dayjs(item))
          : undefined;
      const items = dataCookie?.data?.items || [{}];
      console.log({ deliveryWindow, pickupTime, deliveryDate });
      setFileList(dataCookie.fileList || []);
      setDataForm({ ...dataCookie.data, deliveryDate, pickupTime, deliveryWindow, items });
      form.setFieldsValue({ ...dataCookie.data, deliveryDate, pickupTime, deliveryWindow, items });
      setPickupLocation(dataCookie.pickUpLocation);
      setDeliveryLocation(dataCookie.deliveryLocation);
      setCurrent(dataCookie.current);
      setSelectedType(dataCookie.selectedType || types?.[0]?.type);
    } else {
      form.setFieldsValue({
        items: [{}],
      });
      setSelectedType(types?.[0]?.type);
    }
  }, [types]);

  useEffect(() => {
    if (['create', 'update'].includes(action) && status === 'succeeded') {
      Cookies.set(DRAFT_NAME, JSON.stringify({}));
      navigate('/job-orders');
    }
  }, [isFetching]);

  useEffect(() => {
    if (pickUpLocation?.latLng?.lat && deliveryLocation?.latLng?.lat) {
      dispatch(
        doGetDirections({
          origin: { lat: pickUpLocation.latLng.lat, lng: pickUpLocation.latLng.lng },
          destination: { lat: deliveryLocation.latLng.lat, lng: deliveryLocation.latLng.lng },
        }),
      );
    }
  }, [pickUpLocation, deliveryLocation]);

  useEffect(() => {
    if (directions?.polyline) {
      setPolylines([{ polyline: directions.polyline, strokeColor: '#1961E4', strokeWeight: '8px' }]);
    } else if (polylines.length > 0) {
      setPolylines([]);
    }
  }, [directions, pickUpLocation, deliveryLocation]);

  const handleUploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    const fmData = new FormData();
    const newFileList = [...fileList, { ...file, status: 'uploading' }];
    setFileList(newFileList);
    fmData.append('file', file);
    setIsUpload(true);
    try {
      const { response: fileRs, error } = await requestFormData('/common/shared-upload', {
        method: 'POST',
        data: fmData,
      });
      if (fileRs) {
        const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
        newFileList.splice(fileIndex, 1, {
          uid: fileRs.id,
          ...fileRs,
          name: fileRs.key,
          status: 'done',
        });
        setFileList(newFileList);
        onSuccess('ok');
      } else {
        console.log(error);
        const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
        newFileList.splice(fileIndex, 1, {
          ...file,
          name: file.name,
          status: 'error',
        });
        setFileList(newFileList);
        onError();
      }
    } catch (e) {
      console.log(e);
    }
    setIsUpload(false);
  };

  const removeFile = async (file) => {
    const newFileList = [...fileList];
    const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
    newFileList.splice(fileIndex, 1);
    setFileList(newFileList);
  };

  const continueStep = () => {
    setCurrent(current + 1);
  };

  const clickConfirm = () => {
    form.submit();
  };

  const clickBack = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const saveAsDraft = () => {
    Cookies.set(
      DRAFT_NAME,
      JSON.stringify({ current, data: dataForm, fileList: fileList, pickUpLocation, deliveryLocation, selectedType }),
    );
    api.success({
      message: `Success`,
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e?.fileList)) {
      return e?.fileList;
    }
    return [];
  };

  useEffect(() => {
    const newMarkers = [];
    if (pickUpLocation?.latLng?.lat && pickUpLocation?.latLng?.lng) {
      newMarkers.push({
        id: 1,
        content: <img src={imageMaker(Pickup())} style={{ maxWidth: 40 }} />,
        latitude: Number(pickUpLocation?.latLng?.lat),
        longitude: Number(pickUpLocation?.latLng?.lng),
        offsetX: 0,
        offsetY: 0,
        className: 'order-marker',
      });
    }
    if (deliveryLocation?.latLng?.lat && deliveryLocation?.latLng?.lng) {
      newMarkers.push({
        id: 2,
        content: <img src={imageMaker(Dropoff())} style={{ maxWidth: 40 }} />,
        latitude: Number(deliveryLocation?.latLng?.lat),
        longitude: Number(deliveryLocation?.latLng?.lng),
        offsetX: 0,
        offsetY: 0,
        className: 'order-marker',
      });
    }
    setMarkers(newMarkers);
  }, [pickUpLocation, deliveryLocation]);

  const parseItems = (items) => {
    return (
      items?.map((item) => {
        return {
          ...item,
          weight: item.weight ? Number(item.weight) : null,
          length: item.length ? Number(item.length) : null,
          width: item.width ? Number(item.width) : null,
          height: item.height ? Number(item.height) : null,
          quantity: item.quantity ? Number(item.quantity) : null,
        };
      }) || []
    );
  };

  const handleOnFinish = async () => {
    if (current === 5) {
      const data = {
        deliveryDate: dataForm.deliveryDate.format(DATE_BACKEND_FORMAT),
        pickupTime: dataForm.pickupTime.format(TIME_FORMAT),
        pickupAddress: pickUpLocation.address,
        pickupAddressLat: String(pickUpLocation.latLng.lat),
        pickupAddressLng: String(pickUpLocation.latLng.lng),
        deliveryAddress: deliveryLocation.address,
        deliveryAddressLat: String(deliveryLocation.latLng.lat),
        description: dataForm.description,
        instruction: dataForm.instruction,
        deliveryAddressLng: String(deliveryLocation.latLng.lng),
        fileIds: fileList.filter((file) => file.id).map((file) => file.id),
        contactPhone: dataForm.contactPhone,
        contactName: dataForm.contactName,
        type: selectedType,
        deliveryInstruction: dataForm.deliveryInstruction,
        deliveryContactPhone: dataForm.deliveryContactPhone,
        deliveryContactName: dataForm.deliveryContactName,
        packages: parseItems(dataForm.items),
        deliveryStartTime: dataForm.deliveryStartTime?.format(TIME_FORMAT),
        deliveryEndTime: dataForm.deliveryEndTime?.format(TIME_FORMAT),
      };
      dispatch(doCreateJobOrder(data));
    } else {
      continueStep();
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setDataForm({ ...dataForm, ...allValues });
  };

  const handleRemoveItems = (items, itemKey, remove) => {
    if (items.length === 1) {
      api.error({
        message: `Please have at least 1 item.`,
      });
      return;
    } else {
      remove(itemKey);
    }
  };

  return (
    <Spin spinning={isFetching}>
      {contextHolder}
      <div className="Content CreateJobOrderPage">
        <BreadCrumb breadCrumbs={breadCrumbs} />
        <div className="FormContent">
          <FormTitleHeader title="Create New Order" />
          <Divider />
          <Steps current={current} items={steps} labelPlacement="vertical" />
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={handleOnFinish}
            onValuesChange={onValuesChange}
          >
            <div className="step-content">
              {current === 0 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>Create New P’KUP</h5>
                    </div>
                  </div>
                  <Row gutter={[20, 24]}>
                    <Col span={24}>
                      <Form.Item
                        label="Pickup Location"
                        name="addressPickup"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value && value?.latLng?.lat && value?.latLng?.lng) {
                                return Promise.resolve();
                              }

                              if (isEmpty(value?.addressPickup)) {
                                return Promise.reject(new Error('Required'));
                              }

                              return Promise.reject(new Error('Invalid'));
                            },
                          },
                        ]}
                      >
                        <AddressInput
                          size="large"
                          placeholder="Pickup Location"
                          onSelect={(value) => {
                            form.setFieldsValue({ addressPickup: value });
                            setPickupLocation(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Pickup Time"
                        name="pickupTime"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <TimePicker format={TIME_INPUT_FORMAT} placeholder="Pickup Time" size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Drop off Location"
                        name="addressDelivery"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value && value?.latLng?.lat && value?.latLng?.lng) {
                                return Promise.resolve();
                              }

                              if (isEmpty(value?.addressDelivery)) {
                                return Promise.reject(new Error('Required'));
                              }

                              return Promise.reject(new Error('Invalid'));
                            },
                          },
                        ]}
                      >
                        <AddressInput
                          size="large"
                          placeholder="Delivery Location"
                          onSelect={(value) => {
                            form.setFieldsValue({ addressDelivery: value });
                            setDeliveryLocation(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Date" name="deliveryDate" rules={[{ required: true, message: 'Required' }]}>
                        <DatePicker format={DAY_INPUT_FORMAT} placeholder="Date" size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Delivery Window"
                        name="deliveryWindow"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <TimePicker.RangePicker
                          placeholder={['Start', 'End']}
                          format={TIME_INPUT_FORMAT}
                          size="large"
                          allowEmpty={[false, false]}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={24}>
                      <div className="wrapper-map">
                        <DefaultMapbox markers={markers} className="mapbox-order-detail" polylines={polylines} />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {current === 1 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>Select P’KUP Type</h5>
                    </div>
                    <div className="content">
                      <p>
                        Please make sure that your item(s) fit within the vehicle’s maximum capacity limits.
                        <p>
                          Driver is not expected to lift items greater than 25 KG. Any pickup exceeding this weight
                          limit will require independent arrangements made by customer.
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="types">
                    <Radio.Group value={selectedType}>
                      <Row gutter={[20, 24]}>
                        {types.map((typeValue) => (
                          <Col span={12} key={typeValue.type}>
                            <div
                              className={`type ${typeValue.type === selectedType ? ' active' : ''}`}
                              onClick={() => setSelectedType(typeValue.type)}
                            >
                              <div className="type-header">
                                <Radio value={typeValue.type} />
                                <p>{typeValue.displayName}</p>
                              </div>
                              <div className="type-info">
                                <div className="image">
                                  <img src={typeValue.imageSrc} alt={typeValue.displayName} />
                                </div>
                                <div className="type-description">
                                  <p>{typeValue.description}</p>
                                  <p>(MAX {typeValue.payloadMax} KG)</p>
                                </div>
                                <div className="type-price">
                                  <b>{typeValue.price}</b>
                                  <span>Estimated price</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </div>
                  <div className="note">
                    <p>
                      Depending on availability, the substitution of your preferred vehicle may occur to ensure your
                      package is delivered as quickly and efficiently as possible.
                    </p>
                  </div>
                </>
              )}
              {current === 2 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>Order Details</h5>
                    </div>
                  </div>
                  <Row gutter={[20, 24]}>
                    <Form.List name="items">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field) => (
                              <React.Fragment key={field.key}>
                                <Col span={23}>
                                  <Form.Item
                                    label="Item Name"
                                    name={[field.name, 'name']}
                                    rules={[{ required: true, message: 'Required' }]}
                                  >
                                    <Input placeholder="Item Name" size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={1}>
                                  <div className="type-delete">
                                    <ReactSVG
                                      className="icon"
                                      src="/icons/delete_forever.svg"
                                      onClick={() => handleRemoveItems(fields, field.key, remove)}
                                    />
                                  </div>
                                </Col>
                                <Col span={7}>
                                  <Form.Item label="Length" name={[field.name, 'length']}>
                                    <Input placeholder="Length" size="large" type="number" suffix="cm" />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item label="Width" name={[field.name, 'width']}>
                                    <Input placeholder="Width" size="large" type="number" suffix="cm" />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item label="Height" name={[field.name, 'height']}>
                                    <Input placeholder="Height" size="large" type="number" suffix="cm" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item label="Weight" name={[field.name, 'weight']}>
                                    <Input placeholder="Weight" size="large" type="number" suffix="kg" />
                                  </Form.Item>
                                </Col>
                                <Col span={11}>
                                  <Form.Item label="Quantity" name={[field.name, 'quantity']}>
                                    <Input placeholder="Quantity" size="large" type="number" />
                                  </Form.Item>
                                </Col>
                              </React.Fragment>
                            ))}
                            <Col span={24}>
                              <Button
                                type="link"
                                ghost
                                className="add-item"
                                onClick={() => {
                                  add({});
                                }}
                              >
                                Add Item
                                <ReactSVG className="icon" src="/icons/add_circle_outline.svg" />
                              </Button>
                            </Col>
                          </>
                        );
                      }}
                    </Form.List>
                    <Divider />
                    <Col span={24}>
                      <Form.Item
                        label="Photo/Document of receipt, order number, item(s)"
                        name="documents"
                        getValueFromEvent={normFile}
                      >
                        <Upload.Dragger
                          multiple={false}
                          fileList={fileList}
                          showUploadList={{
                            showPreviewIcon: false,
                            showDownloadIcon: false,
                            showRemoveIcon: true,
                          }}
                          customRequest={handleUploadFile}
                          onRemove={removeFile}
                        >
                          <p className="upload-text">Drag and drop to upload or</p>
                          <p className="upload-drag-icon">
                            Browse
                            <UploadOutlined />
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={24}>
                      <Form.Item label="Instruction Note for Pickup" name="instruction">
                        <Input.TextArea placeholder="Instruction" size="large" rows={8} />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={12}>
                      <Form.Item
                        label="Contact Details for Pickup"
                        name="contactName"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input placeholder="Contact Details for Pickup" size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label=" " name="contactPhone" rules={[{ required: true, message: 'Required' }]}>
                        <PhoneInput
                          country={'us'}
                          prefix="+"
                          specialLabel=""
                          countryCodeEditable
                          enableSearch
                          disableSearchIcon
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {current === 3 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>Drop-off Details</h5>
                    </div>
                  </div>
                  <Row gutter={[20, 24]}>
                    <Col span={12}>
                      <Form.Item
                        label="Contact Details for Drop-off"
                        name="deliveryContactName"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input placeholder="Contact Details for Drop-off" size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label=" "
                        name="deliveryContactPhone"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <PhoneInput
                          country={'us'}
                          prefix="+"
                          specialLabel=""
                          countryCodeEditable
                          enableSearch
                          disableSearchIcon
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={24}>
                      <Form.Item label="Instruction Note for Drop-off" name="deliveryInstruction">
                        <Input.TextArea placeholder="Instruction" size="large" rows={8} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {current === 4 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>Review</h5>
                    </div>
                  </div>
                  <div className="review">
                    <div className="review-content">
                      <label>Pickup Location</label>
                      <p>{dataForm.addressPickup?.address}</p>
                    </div>
                    <div className="review-content">
                      <label>Pickup Time</label>
                      <p>{dataForm.completionTime ? dataForm.completionTime.format(TIME_FORMAT) : 'N/A'}</p>
                    </div>
                    <div className="review-content">
                      <label>Drop off Locationn</label>
                      <p>{dataForm.addressDelivery?.address}</p>
                    </div>
                    <div className="review-content">
                      <label>Delivery Window</label>
                      <p>
                        {dataForm.deliveryWindow?.[0] ? dataForm.deliveryWindow[0].format(TIME_FORMAT) : 'N/A'} -{' '}
                        {dataForm.deliveryWindow?.[1] ? dataForm.deliveryWindow[1].format(TIME_FORMAT) : 'N/A'}
                      </p>
                    </div>
                    <Divider />
                    <div className="review-content">
                      <label>Vehicle Type</label>
                      <p>{types.find((t) => t.type === selectedType)?.displayName}</p>
                    </div>
                    <Divider />
                    <h6 className="sub-title">Pickup From Location</h6>
                    <div className="review-content">
                      <label>Item(s) for pickup</label>
                      <div className="review-items">
                        {dataForm.items.map((item, i) => (
                          <div className="review-item" key={item.name + `${i}`}>
                            <div className="review-item-left">.</div>
                            <div className="review-item-right">
                              <p>{item.name}</p>
                              <p>
                                {item.length || 'N/A'} x {item.width || 'N/A'} x {item.height || 'N/A'} cm |{' '}
                                {item.weight || 'N/A'} kg | Qty: {item.quantity || 'N/A'}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="review-content">
                      <label>Instruction Note for Pickup</label>
                      <p>{dataForm.instruction || 'N/A'}</p>
                    </div>
                    <div className="review-content">
                      <label>Contact Details for Pickup</label>
                      <p>{dataForm.contactName}</p>
                      <p>{dataForm.contactPhone}</p>
                    </div>
                    <Divider />
                    <h6 className="sub-title">Drop-off Details</h6>
                    <div className="review-content">
                      <label>Contact Details for Drop-off</label>
                      <p>{dataForm.deliveryInstruction || 'N/A'}</p>
                    </div>
                    <div className="review-content">
                      <label>Contact Details for Pickup</label>
                      <p>{dataForm.deliveryContactName}</p>
                      <p>{dataForm.deliveryContactPhone}</p>
                    </div>
                  </div>
                </>
              )}
              {current === 5 && (
                <>
                  <div className="header-step">
                    <div className="title">
                      <h5>PAYMENT</h5>
                    </div>
                    <div className="content">
                      <p>Use credit or debit card to pay for your job posting</p>
                    </div>
                    <div className="cost">
                      <b>Total Cost</b>
                      <div className="price-wrapper">
                        <div className="price">
                          <h4>$10.00</h4>
                        </div>
                        <Button size="large" type="link">
                          View Cost Breakdown
                          <ReactSVG className="icon" src="/icons/list_alt.svg" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <Row gutter={[20, 24]}>
                    <Col span={12}>
                      <div className="ant-form-item cards">
                        <label className="ant-form-item-no-colon">Payment Method</label>
                        <div className="card">
                          <h5 className="title-card">New Credit/Debit Card</h5>
                          <div className="input">
                            <label className="ant-form-item-no-colon">Payment Method</label>
                            <Input value="4111 1111 1111 1111" disabled suffix={<img src="/Mastercard.png" />} />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                </>
              )}
            </div>
          </Form>
          <div className="FormFooterNewJobOrder">
            <div className="left">
              {current > 0 && (
                <Button size="large" onClick={clickBack}>
                  Back
                </Button>
              )}
            </div>
            <div className="right">
              <Button size="large" className="ButtonLink" onClick={saveAsDraft}>
                Save As Draft
                <ReactSVG className="icon" src="/icons/save.svg" />
              </Button>
              <Button size="large" className="CreateButton ButtonPrimary" type="primary" onClick={clickConfirm}>
                {current === 5 ? 'Confirm to Create' : 'Continue to Payment'}
                <ReactSVG className="icon" src="/icons/done.svg" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CreateJobOrderPage;
