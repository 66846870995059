import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import './Main.scss';

const MainMenu = () => {
  const location = useLocation();

  let selectedKey = location.pathname;

  if (selectedKey.includes('clients')) {
    selectedKey = '/clients';
  } else if (selectedKey.includes('job-orders')) {
    selectedKey = '/job-orders';
  } else if (selectedKey.includes('companies')) {
    selectedKey = '/companies';
  } else if (selectedKey.includes('routes')) {
    selectedKey = '/routes';
  } else if (selectedKey.includes('settings')) {
    selectedKey = '/settings';
  } else if (selectedKey.includes('pricing')) {
    selectedKey = '/pricing';
  } else if (selectedKey.includes('message-logs')) {
    selectedKey = '/message-logs';
  } else if (selectedKey.includes('drivers')) {
    selectedKey = '/drivers';
  }
  const items = [
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/">Job Orders</Link>
        </div>
      ),
      key: '/job-orders',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/clients">Clients</Link>
        </div>
      ),
      key: '/clients',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/drivers">Drivers</Link>
        </div>
      ),
      key: '/drivers',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/">FAQ</Link>
        </div>
      ),
      key: '/FAQ',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/">Support</Link>
        </div>
      ),
      key: '/Support',
    },
  ];

  return <Menu className={`MainMenu`} selectedKeys={[selectedKey]} mode="horizontal" items={items} />;
};

export default MainMenu;
