import React, { useEffect } from 'react';
import { notification, Typography } from 'antd';

import ErrorContainer from '../modules/error/container';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const Description = ({ errors }) => (
  <div className="notification-description">
    {errors?.map((msg, index) => {
      return (
        <p key={index}>
          <Text type="danger">{msg}</Text>
        </p>
      );
    })}
  </div>
);

const AppError = ({ setError }) => {
  const error = useSelector((state) => state.common.error);
  useEffect(() => {
    const { message, errors } = error;
    if (!message) return;
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        notification.error({
          message: msg,
          style: { top: '52px' },
          description: errors && <Description errors={errors} />,
          placement: 'topRight',
          onClose: () => setError && setError({}),
        });
      });
    } else {
      notification.error({
        message: message,
        style: { top: '52px' },
        description: errors && <Description errors={errors} />,
        placement: 'topRight',
        onClose: () => setError && setError({}),
      });
    }
  }, [error, setError]);

  return <></>;
};

export default ErrorContainer(AppError);
