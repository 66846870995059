import request from '../../helper/request';

export function getUsers(params) {
  return request('/users', {
    method: 'GET',
    params: params,
  });
}

export function getUser(id) {
  return request('/users/' + id, {
    method: 'GET',
  });
}

export function createUser(params) {
  return request('/users', {
    method: 'POST',
    data: params,
  });
}

export function updateUser(params, id) {
  return request('/users/' + id, {
    method: 'PATCH',
    data: params,
  });
}

export function removeUser(id) {
  return request('/users/' + id, {
    method: 'DELETE',
  });
}
