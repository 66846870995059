import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';

import './Auth.scss';

const SignInForm = ({ setParamsLogin, doSignIn }) => {
  const [form] = Form.useForm();

  const handleSubmit = (params) => {
    setParamsLogin && setParamsLogin(params);
    doSignIn && doSignIn(params);
  };

  return (
    <div className={`SignInForm`}>
      <Form form={form} scrollToFirstError layout="vertical" hideRequiredMark colon={false} onFinish={handleSubmit}>
        <div className="Logo">
          <Link to="/">
            <img src={`/logo.png`} alt="logo" />
          </Link>
        </div>
        <div className="FormHeader">
          <div className="Title">Login to your account</div>
        </div>
        <div className="FormContent">
          <Form.Item
            label="Work Email"
            name="email"
            rules={[
              { type: 'email', message: 'Invalid Email' },
              { required: true, message: 'Required' },
            ]}
          >
            <Input size="large" placeholder="name@company.com" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Required' }]}>
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
          <div className="ForgotPassword">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
        <div className="FormFooter">
          <Button type="primary" htmlType="submit" block size="large">
            Login
          </Button>
        </div>
      </Form>
      <div className="Register">
        Don&apos;t have an account? <Link to="/login">Register</Link>
      </div>
    </div>
  );
};

export default SignInForm;
