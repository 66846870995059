import React from 'react';
import { Row, Col } from 'antd';
import withUnAuth from './withUnAuth';
import SignInForm from '../Forms/Auth/SignIn';
import { AuthContainer } from '../../modules/auth/containers';

const SignInPage = ({ doSignIn }) => {
  return (
    <div className="SignInPage">
      <Row>
        <Col xs={0} sm={0} md={{ span: 11 }}>
          <div className="LeftImg">
            <img src={`/login.png`} alt="logo" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={{ span: 12 }}>
          <SignInForm doSignIn={doSignIn} />
        </Col>
      </Row>
    </div>
  );
};

export default withUnAuth(AuthContainer(SignInPage));
