import React from 'react';
import 'react-phone-input-2/lib/material.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux';
import './index.scss';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: { fontFamily: "'Satoshi-Variable', sans-serif" },
          components: {},
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
